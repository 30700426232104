import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The following tables list the aggregation fields of Outbound Campaigns Email aggregation panel, sorted by alphabetic order. Use `}<em parentName="p">{`CRTL + F`}</em>{` in Windows or `}<em parentName="p">{`Command + F`}</em>{` in MacOS to search inside the page.`}</p>
    <h2>{`Campaign Info`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Outbound campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`For `}<strong parentName="td">{`outbound campaigns`}</strong>{`, name of the outbound voice campaign where Engagement Cloud created the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Skill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the skill of the operator or IVR that handled the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Team`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Team of the operator that handled the interaction.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Contact Info`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact list`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the contact list that the campaign used.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email of the contact. For example, `}<inlineCode parentName="td">{`regular.joe@mail.com`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email domain`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Domain of the contact email. For example, `}<inlineCode parentName="td">{`mail.com`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact ID in other campaigns.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Send Info`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact creation date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of creation of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact datetime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date and time when the email was sent.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact quarter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Quarter when the contact happened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact week`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Week when the contact happened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact weekday`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Weekday when the contact happened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date and time of contact`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date and time when the contact started.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of contact creation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of creation of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Day of contact (DD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Day when the contact started.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Day of contact creation (DD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Day when the contact was created.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour of contact (HH)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour when the contact started.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour of contact creation (HH)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour when the contact happened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last status update date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date in which the campaign updated the status of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last status update date (YYYYMMDD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date when status last changed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last status update day (DD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Day when status last changed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last status update day of week`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Week day when status last changed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last status update hour (HH)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour when status last changed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last status update month (MM)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Month when status last changed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last status update quarter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Quarter when status last changed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last status update week`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Week when status last changed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last status update year (YYYY)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year when status last changed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Quarter of contact creation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Month of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Status.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Week of contact creation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Week when the contact was created.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Weekday of contact creation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Week day when the contact was created.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year and month of contact creation (YYYYMM)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year and month when the contact was created.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year of contact (YYYY)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year when the contact started. For example, `}<inlineCode parentName="td">{`2025`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year of contact creation (YYYY)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year when the contact was created.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`field_contact_year_month`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year andd month of contact.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      